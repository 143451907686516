@import (less) './bootstrap.less';
@import (less) './bower_components/magnific-popup/dist/magnific-popup.css';


@col1: #0379ce;
@col2: #777;
@col3: #ccc;
@col4: #3B3F48;

/* some print only stuff */

@media print {
	div.tab-controls .nav-tabs>li.active a {
		font-weight: bold;
	}

	span.badge.green:after {
		content: 'Green';
	}
	span.badge.amber:after {
		content: 'Amber';
	}
	span.badge.red:after {
		content: 'Red';
	}
}


body {
	color: @col2;
	font-family: 'Oswald', Univers, Helvetica, Arial, sans-serif;
	font-weight: 300;

	h1 {
		font-weight: 400;
	}

	h2 {
		font-weight: 300;
	}

	a {
		text-color: @col1;
	}
}

header {
	text-color: #fff;

	div.row.pri {
		background: @col1;

		div.home {
			p {
				font-size: 25px;
				margin: 0.5em 0;
				text-align: right;

				a {
					color: #fff;
				}
			}
		}
	}

	h1 {
		color: #fff;
		font-size: 30px;
		margin: 0.5em 0;

		sub {
			bottom: 0;
			font-size: 30px;
			font-weight: 300;
		}
	}

	div#breadcrumb {
		p {
			color: @col1;
			margin: 0.5em 0;

			a {
				color: @col1;
			}
		}
	}
}

div.tab-controls {
	border-bottom: 1px solid @col3;
	margin-bottom: 1em;

	.nav-tabs {
		border-bottom: 0;
		display: table;
		width: 100%;

		> li, > li.active {
			background: #f3f3f3;
			border-radius: 0;
			cursor: pointer;
			display: table-cell;
			font-size: 14px;
			height: 44px;
			margin: 0;
			padding: 2px 5px;

			> a {
				background: transparent;
				border: 0;
				border-radius: 0;
				color: #666;
				display: block;
				height: 100%;
				margin: 0;
				padding: 0;
			}
		}

		> li.active {
			background: #eee;

			a {
				color: @col1;
			}
		}

		> li:hover {
			background: #eee;

			a {
				color: @col1;
			}
		}

		> li:before, > li.active:before {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			width: 0;
			height: 0;
			border-top: 10px solid #fff;
			border-left: 10px solid transparent;
		}

	}
}

span.new-ribbon {
    background-color: #fa0;
	color: #fff;
	left: -28px;
	padding: 2px;
	position: absolute;
	top: 10px;
	text-transform: uppercase;
	transform: rotate(-35deg);
	text-align: center;
	width: 110px;

	&.draft {
		background-color: #d9534f;
	}

	&.closed {
		background-color: #000;
	}
}

section#main {
	border-bottom: 2px solid #eee;
	padding-top: 10px;

	h2 {
		color: @col1;
		margin: 0 0 10px;
	}

	div.main-selector {
		div.item {
			margin-bottom: 1em;

			h3 {
				background-color: #999;
				color: #fff;
				font-size: 18px;
				font-weight: 300;
				margin: 0;
				padding: 5px;
			}
		}
	}

	div.tab-content {
		div.item {
			margin-bottom: 1em;

			h3 {
				background: @col1;
				color: #fff;
				font-size: 15px;
				font-weight: 300;
				margin: 0;
				padding: 5px;
			}
		}

		div.item.new-product-yes {
			border-left: 15px solid #fff;
			overflow: hidden;
			padding-left: 0;
		}
	}

	article.item-row {
		margin-bottom: 20px;

		h3 {
			color: @col1;
			font-size: 18px;
			margin: 0 0 10px;
			padding: 0 0 10px;
			border-bottom: 1px solid @col3;

			span {
				float: right;
				font-size: 14px;

				a {
					/*font-size: 18px;*/
					padding: 0 4px;
				}
			}
		}

		h4 {
			color: @col1;
			font-size: 16px;
		}

		div.gallery {
			div.item {
				margin-bottom: 10px;
			}
		}
	}

	article.matched-row {
		margin-bottom: 20px;
		padding-bottom: 10px;

		h3 {
			border-bottom: 1px solid @col3;
			color: @col1;
			font-size: 15px;
			margin: 0 0 10px;
			padding-bottom: 5px;

			span.rating {
				font-size: 12px;
				margin: 0 0 5px 15px;
			}
		}


		div.detail {

			h4 {
				font-size: 14px;
				margin: 10px 0 5px;
			}

			p {
				font-weight: 300;
			}

			a {
			}

			div.col-xs-12.desc {
				border-bottom: 1px solid @col3;
			}
		}
	}

	div.product-body {
		background: #fff;
		color: #666;

		h4 {
			color: @col1;
		}

		dl.qa {
			list-style-position: inside;

			dt {
				color: #666;
				display: list-item;
			}

			dd {
				color: #666;
				margin: 0 0 1em 17px;
			}
		}

		table {
			/*border: 1px solid #fff;*/
			border-collapse: collapse;
			border-spacing: 1px;
			width: 100%;

			th, td {
				border: 1px solid #ccc;
				color: #333;
				font-weight: 300;
				padding: 3px;
			}

			th.center, td.center {
				text-align: center;
			}


			thead {
				tr {
					color: @col1;

					th {
						color: @col1;
						font-weight: normal;
					}
				}
			}

			tbody {
				th, td {
					border: 1px solid #fff;
					color: #333;
					font-weight: 300;
					padding: 3px;
				}

				tr {
					background: #cad2e8;
				}

				tr:nth-of-type(odd) {
					background: #e6e9f3;
				}
			}
		}
	}
}

footer {
	margin-top: 20px;

	div.left {
		text-align: left;
	}

	div.right {
		text-align: right;
	}
}

/* some useful classes */
.badge.green { background: #5cb85c; }
.badge.amber { background: #f0ad4e; }
.badge.red { background: #d9534f; }


/* xs */
@media (max-width: @screen-xs-max) {
}

/* small devices */
@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
}

/* medium */
@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
}

/* large */
@media (min-width: @screen-lg-min) {
}
